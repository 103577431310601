import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import HoldingPage from '../pages/Holding';

const AppRouter = () => {
  return (
    <BrowserRouter>
      <React.Fragment>
        <Switch>
          <Route component={HoldingPage} path='/' exact={true} />
        </Switch>
      </React.Fragment>
    </BrowserRouter>
  );
};

export default AppRouter;
