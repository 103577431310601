import React, { useCallback, useEffect, useRef, useState } from 'react';

import styled, { keyframes } from 'styled-components';

import Logo from '../images/La_Perla_Logo_New.svg.png';
import Banner from '../images/CL_270_WELCOME TO LA PERLA BEAUTY PHOTO_V02.jpg';

const glow = keyframes`
  0% {
    box-shadow: none;
  }

  100% {
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  }
`;


const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const MainStyled = styled.div`
  .fadeInLogo {
    animation: ${fadeIn} 500ms ease-in-out 500ms both;
  }

  .fadeInBanner {
    animation: ${fadeIn} 500ms ease-in-out 700ms both;
  }

  .fadeIn {
    animation: ${fadeIn} 500ms ease-in-out 1100ms both;
  }

  .fadeInForm {
    animation: ${fadeIn} 500ms ease-in-out 1300ms both;
  }


  .container {
    max-width: 1200px;
    margin: 60px auto 50px;
    position: relative;
    // display: inline-block;

    @media (max-width: 797px) {
      max-width: 720px;
      padding: 0 !important;
    }

    @media (max-width: 576px) {
      max-width: 540px;
      padding: 0 !important;
    }
  }

  .header-container {
    text-align: center;

    img {
      height: 41.01px;
      width: 220px;
    }
  }

  .banner-container {
    background-image: url("${Banner}");
    background-color: rgba(1, 1, 3, 0.64);

    border-radius: 20px;
    min-height: 387px;
    width: 774px;
    margin: 50px auto 20px;
    background-position: center 5%;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;

    @media (max-width: 797px) {
      border-radius: 0;
      width: 100%;
    }

    .gradient-overlay {
      background-image: linear-gradient(180deg, rgba(1, 1, 3, 0) 0%, rgba(1, 1, 3, 0.64) 100%);
      height: 216px;
      width: 100%;
      border-radius: 0 0 20px 20px;
      position: absolute;

      @media (max-width: 797px) {
        border-radius: 0;
      }
    }

    p {
      color: #ffffff;
      font-family: "Tiempos Fine";
      font-size: 32px;
      letter-spacing: 0.7px;
      line-height: 38px;
      margin-bottom: 30px;
      z-index: 2;
      text-align: center;
      max-width: 100%;
      padding: 0 10px 0;

      @media (max-width: 797px) {
        margin-bottom: 20px;
      }
    }
  }

  .col-12 {
    margin-bottom: 20px;
  }

  input {
    height: 45px;
    // min-width: 529px;
    width: 100%;
    border: 1px solid #efeee9;
    border-radius: 12px;
    margin-top: 9px;
    outline: none !important;
    padding: 10px;

    :focus {
      animation: ${glow} 0.8s ease-in-out 0.1s both;
    }

    @media (max-width: 797px) {
      border-radius: 6px;
    }
  }

  .error-msg {
    // display: none;
    opacity: 0;
    font-size: 11px;
    color: red;
    margin-top: 4px;

    &.show {
      // display: block;
      opacity: 1;
      transition: opacity 1000ms ease;
    }
  }

  label {
    color: #363139;
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 20px;
    text-transform: uppercase;
    margin-bottom: 0;

    .required {
      // color: red;
    }
  }

  .form-container {
    display: flex;
    margin: 20px auto 0;
    max-width: 500px;
    justify-content: center;
  }

  .signUp {
    color: #363139;
    font-family: Quicksand;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 20px;
    text-align: center;
  }

  .disclaimer {
    color: #363139;
    font-family: Quicksand;
    font-size: 12px;
    letter-spacing: 0.8px;
    line-height: 16px;
  }

  .btn-container {
    text-align: right;

    @media (max-width: 797px) {
      text-align: center;
    }

    button {
      height: 45px;
      width: 227px;
      border: 1px solid #efeee9;
      border-radius: 12px;
      background-color: #efeee9;
      color: #858585;
      text-transform: uppercase;
      pointer-events: none;

      @media (max-width: 797px) {
        width: 90%;
        border-radius: 6px;
      }

      &.isValid {
        pointer-events: initial;
        background-color: #000;
        color: #fff;
        transition: all 1000ms ease;
      }
    }
  }
`;

interface FormValues {
  firstName: string;
  lastName: string;
  emailAddress: string;
}

const HoldingPage: React.FC = () => {
  const [values, setValues] = useState<FormValues>({ firstName: '', lastName: '', emailAddress: '' });
  const [isEmailError, setIsEmailError] = useState(false);
  const [isFirstNameError, setIsFirstNameError] = useState(false);
  const [isLastNameError, setIsLastNameError] = useState(false);
  const [y, setY] = useState(window.scrollY);
  const [animate, setAnimate] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      setAnimate(false);
      // if (y > window.scrollY) {
      // 	console.log("scrolling up");
      // } else if (y < window.scrollY) {
      // 	console.log("scrolling down");
      // }
      setY(window.scrollY);
      setAnimate(true);
    },
    [y],
  );

  useEffect(() => {
    // setY(window.scrollY);
    // window.addEventListener("scroll", handleNavigation);

    // return () => {
    // 	window.removeEventListener("scroll", handleNavigation);
    // };

    setAnimate(true);
  }, []);


  const submit = (event: any) => {
    event.preventDefault();

    (async () => {
      const rawResponse = await fetch('https://like.afy.li/us-holding-page/proxy.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: values.emailAddress,
          dataFields: [
            {
              key: 'Firstname',
              value: values.firstName,
            },
            {
              key: 'Lastname',
              value: values.lastName,
            },
            {
              key: 'Created_from',
              value: 'US',
            },
          ],
        }),
      });
      const content = await rawResponse.json();

      if (content.success) {
        setSubmitMessage('Thank you! Your details have been added. ');
      } else {
        setSubmitMessage('Something went wrong. Please try again later.');
      }
    })();
  };

  const handleChange = (event: any) => {
    const { value, name } = event.target;

    if (value === '') {
      if (name === 'firstName') {
        setIsFirstNameError(true);
      } else if (name === 'lastName') {
        setIsLastNameError(true);
      }
    } else {
      if (name === 'firstName') {
        setIsFirstNameError(false);
      } else if (name === 'lastName') {
        setIsLastNameError(false);
      }
      setValues({ ...values, [name]: value });
    }
  };

  const handleEmailChange = (event: any) => {
    const { value, name } = event.target;

    if (value === '' || (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value))) {
      setIsEmailError(true);
    } else {
      setIsEmailError(false);
      setValues({ ...values, [name]: value });
    }
  };

  return (
    <React.Fragment>
      <MainStyled>
        <div className='container'>
          <div className='header-container'>
            <img src={Logo} alt='LPG Logo Text' className={animate ? 'fadeInLogo' : ''} />
          </div>
          <div className={'banner-container ' + (animate ? 'fadeInBanner' : '')}>
            <div className='gradient-overlay'></div>
            <p className={animate ? 'fadeIn' : ''}>La Perla Beauty; coming soon to the US</p>
          </div>
          <div className={'form-container row ' + (animate ? 'fadeInForm' : '')}>
            {submitMessage ? (
              <p>{submitMessage}</p>
            ) : (
              <form onSubmit={submit}>
                <div className='col-12'>
                  <p className='signUp '>Sign up for exclusive access to the new house of beauty</p>
                </div>

                <div className='col-12'>
                  <label htmlFor='firstName'>
                    First Name <span className='required'>*</span>
                  </label>
                  <input type='text' name='firstName' id='firstName' onChange={handleChange} required />
                  <span className={'error-msg ' + (isFirstNameError ? 'show' : '')}>*Cannot be empty</span>
                </div>
                <div className='col-12'>
                  <label htmlFor='lastName'>
                    Last Name<span className='required'>*</span>
                  </label>
                  <input type='text' name='lastName' id='lastName' onChange={handleChange} required />
                  <span className={'error-msg ' + (isLastNameError ? 'show' : '')}>*Cannot be empty</span>
                </div>
                <div className='col-12'>
                  <label htmlFor='emailAddress'>
                    Email<span className='required'>*</span>
                  </label>
                  <input type='text' name='emailAddress' id='emailAddress' onChange={handleEmailChange} required />
                  <span className={'error-msg ' + (isEmailError ? 'show' : '')}>*Cannot be empty</span>
                </div>

                <div className='col-12'>
                  <p className='disclaimer'>By entering your email you agree to accept marketing from La Perla Global Management in line with our Privacy Policy. </p>
                </div>

                <div className='btn-container'>
                  <button type='submit' className={(values.firstName === '' || values.lastName === '' || values.emailAddress === '') ? '' : 'isValid'}>
                    Submit
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </MainStyled>
    </React.Fragment>
  );
};

export default HoldingPage;
